<template>
  <div>
    <div class="shopping-item">
      <div class="shopping-item-top">
        <div class="shopping-item-top-left">
          <span class="iconfont iconshops"></span>
          <span v-if="!orderlist.store">平台自营</span>
          <span v-else>{{orderlist.store.title}}</span>
          <span class="iconfont iconqianjin"></span>
        </div>
        <span class="shopping-item-top-type">{{orderlist.text_status | order_status}}</span>
      </div>
      <div class="shopping-item-main" v-for="(good, myidx) in orderlist.goods" :key="myidx" @click="goInfor">
        <div class="shopping-item-main-img">
          <img :src="good.image_url" alt />
        </div>
        <div class="shopping-item-main-right">
          <div class="shopping-item-name">
            <span>{{good.name}}</span>
            <div class="shopping-item-name-right">
              <span style="color:rgba(51,51,51,1);">￥{{good.price}}</span>
              <span>x{{good.nums}}</span>
            </div>
          </div>
          <span>{{good.addon}}</span>
          <div class="shopping-item-main-right-price">
            <span>标签</span>
          </div>
        </div>
      </div>
      <div class="shopping-price">
        <span>共1件商品</span>
        <span>
          合计:
          <span style="color:#FF8000">￥100</span>
        </span>
      </div>
      <div class="bottom-btn">
        <div v-if="orderlist.text_status == 1" @click="goPay">立即付款</div>
        <div class="out-order" v-if="orderlist.text_status == 1" @click="outPay">取消订单</div>
        <div v-if="orderlist.text_status == 3" @click="confirmGoods(orderlist.ordersn)">确认收货</div>
        <div v-if="orderlist.text_status == 4">再次购买</div>
        <div v-if="orderlist.text_status == 4" @click="goEvaluate()">评价</div>
        <div v-if="orderlist.text_status == 6" @click="delOrder(orderlist)">删除订单</div>
      </div>
    </div>
  </div>
</template>
<script>
import Order from "@/api/order";
import { getWxConfig } from "@/utils/wxpay";
export default {
  filters: {
    // 订单总状态 1=待付款 2=待发货 3=待收货 4=待评价 5=已评价 6=已完成 7=已取消
    order_status: function(val) {
      switch (val) {
        case 1:
          return "待付款"
        case 2:
          return "待发货"
        case 3:
          return "待收货"
        case 4:
          return "待评价"
        case 5:
          return "已评价"
        case 6:
          return "已完成"
        case 7:
          return "已取消"
        default:
          break;
      }
    }
  },
  props: {
    orderlist: {
      type: Object
    }
  },
  mounted() {
    console.log(this.orderlist);
  },
  methods: {
    // 支付订单
    async goPay() {
      try {
        const res = await Order.goPayOrder(this.orderlist.ordersn, 3);
        if (res.code == 200) {
          // 调用支付
          getWxConfig(this.orderlist.ordersn);
          this.$toast('订单支付成功')
        } else {
          this.$toast('支付失败')
        }
        console.log(res);
      } catch(err) {
        console.log(err);
      }
    },
    // 删除订单
    delOrder(item) {
      console.log(item);
      this.$dialog.confirm({
        title: '删除订单',
        message: '您确定要删除该订单吗，将永久删除该订单...'
      }).then(() => {
        Order.delLongOrder(item.store_id, item.ordersn).then(res =>{
          if (res.data == 200) {
            this.$toast(res.msg);
          } else {
            this.$toast(`删除失败 ${res.msg}`);
          }
        })
      }).catch(() => {
        console.log("取消删除订单...");
      });
    },
    // 取消订单
    outPay() {
      this.$emit('outPay', this.orderlist.ordersn);
    },
    // 确认收货
    async confirmGoods(ordersn) {
      try {
        const res = await Order.makeGoods(ordersn);
        if (res.code == 200) {
          this.$toast(res.msg)
        } else {
          this.$toast('确认收货失败')
        }
        console.log(res);
      } catch(err) {
        console.log(err);
      }
    },
    // 评价订单
    goEvaluate() {
      this.$router.push({
        path: "/evaluate",
        query: {
          ordersn: this.orderlist.ordersn
        }
      })
    },
    // 订单详情
    goInfor() {     
      this.$router.push({
        path: "orderDetails",
        query: {
          ordersn: this.orderlist.ordersn
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.shopping-item {
  width: 94%;
  height: auto;
  display: flex;
  padding: 8px 10px;
  border-radius: 4px;
  background: #ffffff;
  flex-direction: column;
  margin: 16px auto;
  .shopping-item-top {
    width: 100%;
    height: 36.5px;
    display: flex;
    color: #333333;
    justify-content: space-between;
    align-items: center;
    .shopping-item-top-left {
      width: 86%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        margin-left: 6px;
      }
    }
    .shopping-item-top-type {
      font-size: 14px;
      color: #ff8000;
    }
  }
  .shopping-item-main {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .shopping-item-main-img {
      width: 84px;
      height: 84px;
      margin-left: 6px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
    .shopping-item-main-right {
      width: 260px;
      height: 100%;
      display: flex;
      padding: 10px;
      flex-direction: column;
      justify-content: space-between;
      .shopping-item-name {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content:space-between;
        .shopping-item-name-right {
          display: flex;
          text-align: right;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
      .shopping-item-main-right-price {
        flex: 1;
        font-size: 14px;
        align-items: center;
        display: flex;
        color: #ff8000;
        justify-content: space-between;
      }
    }
  }
  .shopping-price {
    width: 100%;
    line-height: 20px;
    text-align: right;
    padding-bottom: 5px;
  }
  .bottom-btn {
    width: 100%;
    height: auto;
    display: flex;
    margin-left: 5px;
    align-items: center;
    justify-content: flex-end;
    div {
      width: 70px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      color: #ff8000;
      border:1px solid #ff8000;
      border-radius: 12px;
      margin-left: 10px;
    }
    .out-order {
      color: #7c7c7c;
      border:1px solid rgba(191,190,190,1);
      margin-left: 10px;
    }
  }
}
</style>
