<template>
	<div class="order-list">
		<Nav navType="backnav" textCon="订单列表" style="position: relative!important;height:45px"></Nav>
		<Tabs
			:swipe-threshold="6"
      animated swipeable
			v-model="active"
			class="order-tabs"
			style="z-index:200"
      sticky
		>
			<Tab v-for="(item, idx) in tabs" :key="idx" :title="item" >
        <List
          v-model="loading_status"
          :finished="more_status"
          finished-text="没有更多了"
          @load="getOrderList()"
        >
          <OrderItem
            v-for="(item, idx) in orderlist"
            :key="idx"
            :orderlist="item"
          >
          </OrderItem>
        </List>
      </Tab>
		</Tabs>

		<!--取消订单-->
		<Popup
			v-model="showModel"
			closeable
			position="bottom"
			:style="{ height: '68%' }"
		>
			<div class="pay-type">取消订单</div>
			<div class="remind">
				<span>温馨提醒：</span>订单取消后将无法恢复，特价、优惠等资格一并取消，
				优惠券、积分、余额等将返还到您的账户中，请及时使用
			</div>
			<RadioGroup v-model="radio">
				<CellGroup>
					<Cell
						:title="item"
						clickable
						@click="radio = idx"
						v-for="(item, idx) in personList"
						:key="idx"
					>
						<Radio slot="right-icon" :name="idx"></Radio>
					</Cell>
				</CellGroup>
			</RadioGroup>
			<Button
				size="large"
				class="make-person"
				@click="outOrder"
				color="linear-gradient(to right, #FF8000, #FFB101)"
			>
				确定
			</Button>
		</Popup>
	</div>
</template>
<script>
import Order from "@/api/order";
// import CreditShop from "@/api/creditshop"
import Nav from "@components/public/searchTop";
import OrderItem from "@components/public/orderItem";
// import OilorderItem from "@components/public/oilorderItem";
import { Tab, Tabs, Button, Popup, RadioGroup, Radio, List, Cell, CellGroup } from "vant";
export default {
  components: {
    Tab,
    Tabs,
    Nav,
    Cell,
    Popup,
    Radio,
    Button,
    List,
    OrderItem,
    CellGroup,
    RadioGroup,
    // OilorderItem
  },
  data() {
    return {
      active: this.$route.query.active || 0,
      orderlist: [],
      limit: 10,
      page: 1,
      radio: '1',
      nowOrdersn: "",
      showModel: false,
      loading_status: false,
      more_status: false,
      tabs: ["全部", "待付款", "待发货", "待收货", "待评价"],
      personList: [
        '我不想买了',
        '信息填写错误(地址、联系人、支付方式等)',
        '重复下单/误下单', '对价格不满，等降价了再买',
        '商家缺货，无法支付',
        '未参加优惠，重新购买',
        '其他原因'
      ]
    };
  },
  watch: {
    active: function(val) {
      this.page = 0;
      this.orderlist = [];
      this.getOrderList(val);
    }
  }, 
  methods: {
    async getOrderList(val) {
      try {
        const res = await Order.getOrderAll(10, this.page++, val,);
        if (res.code == 200) {
          for (let i in res.data) {
            let obj = res.data[i];
            this.orderlist.push(obj)
          }
          this.loading_status = false;
          this.$nextTick(() => {
            if (res.total == this.orderlist.length) {
              this.more_status = true;
              this.page = 1;
            } else {
              console.log('aaaaaaaaaa');
            }
          })
        } else {
          throw(res.msg);
        }
      } catch(err) {
        this.$toast(err);
      }
    },
    // 展示退款条件
    showTianjian(ordersn) {
      this.showModel = true;
      this.nowOrdersn = ordersn;
    },
    // 取消订单
    async outOrder() {
      const res = await Order.outOderPay(this.nowOrdersn, this.personList[this.radio], "");
      if (res.code == 200) {
        this.$toast("退款成功...");
        this.showModel = false;
      } else {
        throw(res.msg);
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.order-list {
	width: 100%;
	height: 100vh;
  overflow-y: scroll;
	background: #f4f4f4;
}

.shopping-list {
	width: 100%;
	height: auto;
  padding-bottom: 40px;
	overflow-y: scroll;
}
.shopping-list-con {
	width: 100%;
	padding-top: 6px;
}
.pay-type {
	width: 100%;
	height: 45px;
	font-size: 16px;
	color: #333333;
	line-height: 45px;
	text-align: center;
	border-bottom: 1px solid #dfdfdf;
}
.remind {
	width: 100%;
	line-height: 20px;
	padding: 4px 10px;
	font-size: 12px;
}
.make-person {
	width: 98%;
	left: 50%;
	height: 50px;
	line-height: 50px;
	margin-left: -49%;
	border-radius: 8px;
}
</style>
